<template>
  <div>
    <search-hall @submit="submitSearch" />
    <div class="custom-table-operation-buttons">
      <a-button type="primary" @click="showNewModal" html-type="button">
        新增
      </a-button>
    </div>

    <a-table
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="fetchData"
    >
      <template slot="image" slot-scope="image, record">
        <!--        <span-->
        <!--          v-if="img"-->
        <!--          class="custom-blue"-->
        <!--          @click="showImg(record)"-->
        <!--        >{{ img.response.filename }}</span>-->
        <img v-if="image && image.length > 0" :src="image[0].url" style="height: 50px;width: 50px" @click="showImg(record)">
      </template>
      <template slot="price" slot-scope="price">
        <span>{{ price +'元/小时' }}</span>
      </template>
      <template slot="type" slot-scope="type">
        <span>{{ type.toUpperCase() +'类' }}</span>
      </template>
      <div slot="effective" slot-scope="effective, record">
        <a-switch
          :checked="effective"
          :loading="editingEffectiveId === record.id"
          @change="(checked) => changeEffective(checked, record)"
        />
      </div>
      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="showUpdateStatusModal(record)">修改工作状态</a>
          <a @click="showEditModal(record)">编辑</a>
          <a @click="showOperationHistoryModal(record.id)">操作历史</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 新增厅房模态框 -->
    <new-hall
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      :funeral-home-id="funeralHomeId"
      @completed="fetchData"
    />

    <!-- 编辑厅房模态框 -->
    <edit-hall
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      :record="editingRecord"
      @completed="fetchData"
    />
    <!--  修改工作状态模态框  -->
    <work-status-hall
      v-if="isShowUpdateWorkStatusModel"
      :visible.sync="isShowUpdateWorkStatusModel"
      :record="updatingRecord"
      @completed="fetchData"
    />
    <!-- 预览图片 -->
    <preview-image
      :visible.sync="previewVisible"
      :images.sync="previewImages"
    />

    <operation-history-list-modal
      v-if="isShowOperationHistoryModal"
      :reference-id="showingOperationHistoryId"
      reference-type="Hall"
      :visible.sync="isShowOperationHistoryModal"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import SearchHall from '@/views/halls/Search'
import PreviewImage from '@/components/PreviewImage'
import { findHalls, updateHallEffective } from '@/api/hall'

export default {
  name: 'HallList',
  components: {
    Pagination,
    PreviewImage,
    SearchHall,
    NewHall: () => import('@/views/halls/New'),
    EditHall: () => import('@/views/halls/Edit'),
    WorkStatusHall: () => import('@/views/halls/Status'),
    OperationHistoryListModal: () => import('@/views/operation_histories/Modal')
  },
  data() {
    return {
      data: [],
      loading: true,
      funeralHomeId: 0,
      isShowNewModal: false, // 是否显示新增表单弹窗
      isShowEditModal: false, // 是否显示编辑表单弹窗
      isShowUpdateWorkStatusModel: false, // 是否显示修改工作状态表单弹窗
      editingEffectiveId: 0, // 正在修改有效性的记录id
      updatingRecord: {}, // 正在修改工作状态记录id
      editingRecord: {}, // 正在编辑的记录id
      previewVisible: false,
      previewImages: [],
      showingOperationHistoryId: 0,
      isShowOperationHistoryModal: false,
      pagination: {
        total_count: 0
      },
      query: {}
    }
  },
  created() {
    this.fetchData()
    this.$EventBus.$on('showImg', val => {
      this.previewVisible = true
      this.previewImages = val.images
    })
  },
  destroyed() {
    this.$EventBus.$off('showImg')
  },
  computed: {
    columns() {
      return [
        {
          title: '厅房名称',
          dataIndex: 'name',
          fixed: 'left',
          width: 250
        },
        {
          title: '类型',
          width: 60,
          dataIndex: 'hall_type',
          scopedSlots: { customRender: 'type' }
        },
        {
          title: '价格',
          width: 120,
          dataIndex: 'hall_price',
          scopedSlots: { customRender: 'price' }
        },
        {
          title: '图片',
          width: 80,
          dataIndex: 'images',
          scopedSlots: { customRender: 'image' }
        },
        {
          title: '工作状态',
          width: 90,
          dataIndex: 'status_text'
        },
        {
          title: '生效状态',
          width: 90,
          dataIndex: 'effective',
          scopedSlots: { customRender: 'effective' }
        },
        {
          title: '介绍',
          width: 250,
          dataIndex: 'remark',
          ellipsis: true
        },
        {
          title: '逝者姓名',
          width: 90,
          dataIndex: 'death_name'
        },
        {
          title: '逝者性别',
          width: 90,
          dataIndex: 'death_sex'
        },
        {
          title: '关联订单号',
          dataIndex: 'order_no',
          width: 120,
          ellipsis: true
        },
        {
          title: '开始时间',
          dataIndex: 'created_at',
          width: 90,
          ellipsis: true
        },
        {
          title: '操作',
          width: 240,
          fixed: 'right',
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    }
  },
  methods: {
    showImg(record) {
      this.previewVisible = true
      this.previewImages = record.images
    },
    // 更新事件
    showNewModal() {
      this.funeralHomeId = Number(this.$route.params.id)
      this.isShowNewModal = true
    },

    // 编辑事件
    showEditModal(record) {
      this.editingRecord = record
      this.isShowEditModal = true
    },

    // 更新工作状态的事件
    showUpdateStatusModal(record) {
      this.updatingRecord = record
      this.isShowUpdateWorkStatusModel = true
    },

    // 显示操作历史
    showOperationHistoryModal(id) {
      this.showingOperationHistoryId = id
      this.isShowOperationHistoryModal = true
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findHalls(this.$route.params.id, this.query).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    // 控制有效性的
    changeEffective(checked, record) {
      const vm = this
      const effective = checked
      this.$confirm({
        title: effective ? '确定将厅房生效吗?' : '确定失效厅房吗?',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          vm.editingEffectiveId = record.id
          updateHallEffective(record.id, { effective: effective }).then((res) => {
            vm.editingEffectiveId = 0
            if (res.code === 0) {
              record.effective = checked
            }
          }).catch(() => {
            vm.editingEffectiveId = 0
          })
        }
      })
    }
  }
}
</script>
